<template>
  <div>
    <b-card>
      <div v-show="!loading.services">
        <table-render id="info-carrier" :schema="schema" :rows="rows" :actions="actions">
        </table-render>
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.services"
      />
      <div class="table-render-skeleton" v-show="loading.services">
        <b-skeleton-table
          :rows="3"
          :columns="schema.length || 3"
          :table-props="{}"
        />
      </div>
      <ModalImportFreightCharges :item="item"></ModalImportFreightCharges>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalImportFreightCharges from './ModalImportFreightCharges.vue'

export default {
  name: 'carrier-details',
  components: { ModalImportFreightCharges },
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      carrierName: '',
      carrierCode: '',
      update: true,
      item: '',
      loading: {
        services: true
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      carrierServices: 'getCarrierServicesNY',
      carrier: 'getCarrierNY',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    carrier() {
      this.carrierName = this.carrier.name
      this.carrierCode = this.carrier.countryCode
    },
    carrierServices() {
      this.rows = this.carrierServices.rows.map(({aliases, ...rest}) => {
        return {
          ...rest,
          aliases: aliases.join(', ') || 'Sin alias',
          carrierName: this.carrierName || 'Nombre no suministrado',
          countryCode: this.carrierCode || 'Codigo no suministrado'
        }
      })
    },
    generalLoading: {
      handler () {
        this.loading.services = !!this.generalLoading.getCarrierServicesNY
      }
    }
  },
  methods: {
    setInitialData() {
      this.actions = [
        {
          action: id => this.goToRates(id),
          icon: 'GlobeIcon',
          color: 'success',
          text: 'Rates'
        }, 
        {
          action: id => this.updateCoverage(id),
          icon: 'UploadIcon',
          color: 'success',
          text: 'Actualizar cobertura'
        },
        {
          action: id => this.futureCoverage(id),
          icon: 'UploadIcon',
          color: 'success',
          text: 'Programar cobertura'
        },
        {
          action: id => this.freightCharges(id),
          icon: 'UploadIcon',
          color: 'success',
          text: 'Cargar Fletes'
        }

      ]
      this.schema = [
        { label: 'Id', sortable: true, key: 'id' },
        { label: 'Courier', sortable: true, key: 'carrierName' },
        { label: 'Servicio', sortable: true, key: 'name' },
        { label: 'Codigo', sortable: true, key: 'code' },
        { label: 'Alias', sortable: true, key: 'aliases' },
        { label: 'Pais', sortable: true, key: 'countryCode' },
        { label: 'Opciones', key: 'actions', class: ['text-center'] }
      ]
      this.getDataServicesCarrier()
    },
    getDataServicesCarrier() {
      const params = { carrier_id: this.$route.params.id }
      const services = [
        { name: 'getCarrierNY', params },
        { name: 'getCarrierServicesNY', params }
      ]
      this.$store.dispatch('fetchMultipleServices', {services})
    },
    goToRates(id) {
      this.$router.push({ name: 'carrier-service-rates', params: { carrier_id: this.$route.params.id, service_id: id }})
    },
    updateCoverage(id) {
      this.update = true
      this.item = this.rows.find(el => el.id === id)
      this.$bvModal.show('ModalImportCoverage')
    },
    futureCoverage(id) {
      this.update = false
      this.item = this.rows.find(el => el.id === id)
      this.$bvModal.show('ModalImportCoverage')
    },
    freightCharges(id) {
      this.item = this.rows.find(el => el.id === id)
      this.$bvModal.show('ModalImportFreightCharges')
    }
  }
}
</script>
